<template lang="pug">
  article.objects.min-h-screen.bg-vanilla.text-navy.font-messina.pt-16.pb-8.flex.items-start.justify-center.lg_items-center
    transition(name="crossfade")
      .w-full.sm_max-w-record.lg_max-w-artboard.flex.flex-wrap.lg_flex-no-wrap.justify-start.px-4.lg_px-0(v-if="doc")
        h1.sr-only Objects
        //- objects...
        template(v-for="(object, i) in doc.data.objects")
          section.mx-auto.mb-24.pb-4.cursor-pointer.lg_mx-0.lg_flex.lg_flex-col(@click="current = i", :class="[itemSize(i), itemMargins(i)]")
            .flex-1.w-full.flex.items-center.justify-center
              resp-img.w-full(:image="object.image")
            h2.mt-10.text-center.w-full {{ $prismic.asText(object.title) }}

          //- overlay with description
          .fixed.overlay.z-10.bg-vanilla.flex.lg_items-center.py-16.overflow-y-scroll.scrollbars-hidden.py-20(:class="{'pointer-events-none invisible': current !== i}")
            .w-full.max-w-artboard.lg_flex.items-center
              figure.px-4.lg_w-1x2.lg_flex.justify-center.lg_px-0
                .mx-auto.px-4.lg_px-0(:class="itemSize(i)")
                  .w-auto.lg_w-full3x.lg_transform.lg_-translate-x-1x4
                    template(v-if="object.video.url")
                      video.w-full(:ref="'video' + i", :src="object.video.url", muted, playsinline)
                    template(v-else)
                      resp-img.w-full(:image="object.image")
              .px-8.mt-20.lg_w-1x2.lg_pl-32
                rich-text.leading-relaxed.children-mt-lh-relaxed.lg_mx-auto.links-underline-red(:field="object.description", style="max-width: calc(459/16*1em)")

            close-btn-x.fixed.z-20.top-0.right-0(@click="current = -1")
</template>

<script>
import store from '@/store'
export default {
  name: 'Objects',
  data () {
    return {
      current: -1
    }
  },
  computed: {
    doc () {
      return this.$store.state.docs.find(doc => doc.type === 'objects')
    }
  },
  methods: {
    itemSize (i) {
      return {
        'w-2x3 lg_w-object-1': i === 0,
        'w-full lg_w-object-2': i === 1,
        'w-2x3 lg_w-object-3': i === 2
      }
    },
    itemMargins (i) {
      return {
        'lg_mx-40 lg_ml-52': i === 0,
        '': i === 1,
        'lg_mx-32': i === 2
      }
    }
  },
  watch: {
    current (curr) {
      // un/lock body scroll (mobile)
      document.body.style.overflow = curr >= 0 ? 'hidden' : ''
      // start videos from beginning
      const vid = this.$refs['video' + curr]
      if (vid && vid[0]) {
        setTimeout(() => vid[0].play(), 200)
      }
      // on close, reset to 0
      if (curr < 0) {
        Array.from(this.$el.querySelectorAll('video')).forEach(video => {
          if (!video.paused) video.pause()
          video.currentTime = 0
        })
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    store.commit('setUIColor', 'navy')
    next()
  }
}
</script>

<style>
</style>
